import { useState, useEffect, useContext, createContext } from 'react'
import * as React from 'react'
import { firebaseClient, firestore } from '../src/firebaseClient'
import { useForm } from 'react-hook-form'
import Image from 'next/image'
import 'firebase/auth'
import Link from 'next/link'
import { useAuth } from '../src/hooks/useAuth'
import { useRouter } from 'next/router'
import { useIntercom } from 'react-use-intercom'
import { XCircleIcon } from '@heroicons/react/outline'
import { useLoading } from '@/components/hooks/useLoading'
import {
  query,
  collection,
  where,
  getDocs,
  doc,
  setDoc,
} from 'firebase/firestore'

interface Inputs {
  email: string
  password: string
  rememberMe: boolean
  terms: boolean
  values: string
}

const SignIn = (_props: any) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onSubmit',
    // reValidateMode: 'onBlur',
    shouldFocusError: true,
  })

  const { setLoading } = useLoading()

  const [serverError, setServerError] = useState('')
  const { user, signIn } = useAuth()
  const router = useRouter()

  useEffect(() => {
    const handleUserCheck = async () => {
      if (user?.uid) {
        const subscriptionRef = collection(
          firestore,
          `users/${user.uid}/subscriptions`
        )
        const subscriptionQuery = query(
          subscriptionRef,
          where('status', 'in', ['trialing', 'active', 'past_due'])
        )
        const userSub = await getDocs(subscriptionQuery)

        setLoading(false)
        if (!userSub.empty) {
          return router.push({
            pathname: '/account',
            query: router.query.via ? { via: router.query.via } : {},
          })
        }
        return router.push({
          pathname: '/account/subscription',
          query: router.query.via ? { via: router.query.via } : {},
        })
      }
    }
    handleUserCheck()
  }, [user])
  const onSubmitForm = async (data) => {
    try {
      const { email, password } = data
      setLoading(true, 'Getting your account...')
      await signIn(email, password)
    } catch (error) {
      setLoading(false)
      setServerError(error.message)
    }
  }

  return (
    <div className=' min-h-screen bg-gray-800 flex'>
      <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div>
            <Link
              href={{
                pathname: '/',
                query: router.query.via ? { via: router.query.via } : {},
              }}>
              <Image
                className=' block'
                src='/MainLogo-assets/juggBJJLogo.png'
                alt='Juggernaut AI'
                objectFit='contain'
                width={450}
                height={150}
              />
            </Link>
            <h2 className='mt-6 text-3xl font-extrabold text-gray-100'>
              Sign in to your account
            </h2>
          </div>

          <div className='mt-6'>
            <form className='space-y-6' onSubmit={handleSubmit(onSubmitForm)}>
              <input type='hidden' name='remember' value='true' />
              <div className='rounded-md shadow-sm -space-y-px'>
                {/* email */}
                <div>
                  <label
                    htmlFor='email-address'
                    className='mt-3 block text-sm font-medium text-white'>
                    Email address
                  </label>
                  <div className='mt-1'>
                    <input
                      id='email-address'
                      name='email'
                      type='text'
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-primary-500 sm:text-sm'
                      placeholder={'Email'}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'A valid email is required.',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'The email address is badly formatted.',
                        },
                      })}
                    />
                    {errors.email ? (
                      <div className='text-red-500'>{errors.email.message}</div>
                    ) : null}
                  </div>
                </div>
                {/* password */}
                <div className='space-y-1'>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium mt-3 text-white'>
                    Password
                  </label>
                  <div className='mt-1'>
                    <input
                      id='password'
                      name='password'
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400  focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                      type={'password'}
                      placeholder={'Password'}
                      {...register('password', {
                        required: {
                          value: true,
                          message: 'Your password is required.',
                        },
                      })}
                    />

                    {errors.password ? (
                      <div className='text-red-500'>
                        {errors.password.message}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* Terms checkbox */}
              {serverError !== '' && (
                <div className='flex items-center'>
                  <div className='rounded-md bg-red-50 p-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <XCircleIcon
                          className='h-5 w-5 text-red-400'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='ml-3'>
                        <h3 className='text-sm font-medium text-red-800'>
                          Error
                        </h3>
                        <div className='mt-2 text-sm text-red-700'>
                          <ul className='list-disc pl-5 space-y-1'>
                            <li>{serverError}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className='flex items-center justify-between'>
                {/* forgot password */}
                <div className='text-sm'>
                  <Link
                    href={{
                      pathname: '/forgot',
                      query: router.query.via ? { via: router.query.via } : {},
                    }}>
                    <a className='text-primary-500'>Forgot password?</a>
                  </Link>
                </div>
              </div>
              <div>
                <button
                  className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                  type='submit'>
                  Sign in
                </button>

                <Link
                  href={{
                    pathname: '/register',
                    query: router.query.via ? { via: router.query.via } : {},
                  }}>
                  <button className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-gray-800 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 mt-5 border-primary-500 text-primary-500 hover:text-white'>
                    Register
                  </button>
                </Link>
                {/* <div className="pt-2 flex text-xs items-start justify-center">
              <p >Don't have an account? <Link href="/register"><a className="text-primary-500 hover:text-primary-900 hover:underline">Register here.</a></Link></p>
              </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='hidden lg:block relative w-0 flex-1'>
        <Image
          src='/Screenmap.jpeg'
          alt='Juggernaut AI'
          layout='fill'
          objectFit='cover'
        />
      </div>
    </div>
  )
}

export default SignIn
